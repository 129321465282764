import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";

export default function Header() {
    const { t } = useTranslation();
    const { lang } = useParams();

    return (
        <Container className="flex flex-col items-center mb-5">
            <Link className="mt-4 mb-4" to={`${lang ? `/${lang}` : "/"}`}>
                <img
                    className="w-40"
                    src="/images/logo.svg"
                    alt="eufy Georgia logo"
                />
            </Link>
            <h2 className={"text-3xl"}>{t("landing.eufy-georgia")}</h2>
            <p className={"mt-2 mb-3 text-center text-lg text-gray-500"}>
                {t("landing.slogan")}
            </p>
        </Container>
    );
}
