import Row from "react-bootstrap/Row";
import s from "./styles.module.css";

export default function Delivery() {
    return (
        <>
            <Row>
                <h2 className="text-3xl text-center mb-4 underline">
                    მიწოდების პირობები
                </h2>
            </Row>
            <Row>
                <ul className={s.unorderedList}>
                    <li className={s.listItem}>
                        თბილისში (1-2 სამუშაო დღე) - უფასო
                        {/* - 5 ლარი */}
                    </li>
                    <li className={s.listItem}>
                        რეგიონებში (1-4 სამუშაო დღე) - უფასო
                        {/* - 10 ლარი */}
                    </li>
                </ul>
            </Row>
        </>
    );
}
