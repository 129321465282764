import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function CustomModal({ showModal, setShowModal }) {
    // const [shipping, setShipping] = useState(1);
    // const total = price + (shipping === 1 ? 5 : shipping === 2 ? 10 : 0);

    // async function submit(event) {
    //     try {
    //         event?.preventDefault();
    //         console.log(
    //             event.target.name.value,
    //             event.target.address.value,
    //             event.target.phoneNumber.value,
    //             shipping
    //         );
    //         // const response = await fetch(
    //         //     "https://faas-fra1-afec6ce7.doserverless.co/api/v1/web/fn-62fb0500-2cd3-4132-8034-dcb50e46f110/qr/qr",
    //         //     {
    //         //         method: "POST",
    //         //         headers: {
    //         //             "Content-Type": "application/json",
    //         //         },
    //         //         body: JSON.stringify({
    //         //             name: "გიორგი",
    //         //             address: "თბილისი",
    //         //             phone: "555555555",
    //         //             total: total,
    //         //         }),
    //         //     }
    //         // );
    //         // const data = await response.json();
    //         // console.log(data);

    //         // window.location.href = "https://egreve.bog.ge/vkupar";
    //     } catch (error) {
    //         alert(
    //             "დაფიქსირდა შეცდომა. გთხოვთ დაგვიკავშირდით Facebook-ზე ან ნომერზე: 574 180 111"
    //         );
    //     }
    // }

    return (
        <div className="top-shadow-sm mt-5 mb-3">
            <Modal
                centered
                show={showModal}
                // fullscreen="sm-down"
                onHide={() => setShowModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-xl">
                    პროდუქტის შესაძენად მოგვწერე{" "}
                    <Link
                        className="underline"
                        to="https://facebook.com/eufy.ge"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Facebook
                    </Link>
                    -ზე ან დაგვირეკე:{" "}
                    <Link
                        to="tel:574180111"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        574 180 111
                    </Link>
                </Modal.Body>
            </Modal>
        </div>
    );
}

// Name, Address, Phone

{
    /* <Form onSubmit={submit}>
                        <FloatingLabel
                            controlId="name"
                            label="სახელი"
                            className="mb-3"
                        >
                            <Form.Control
                                type="text"
                                placeholder="სახელი"
                                name="firstName"
                            />
                        </FloatingLabel>
                        <FloatingLabel
                            controlId="address"
                            label="მისამართი"
                            className="mb-3"
                        >
                            <Form.Control
                                type="text"
                                placeholder="მისამართი"
                                name="address"
                            />
                        </FloatingLabel>
                        <FloatingLabel
                            controlId="phoneNumber"
                            label="ტელ. ნომერი"
                            className="mb-3"
                        >
                            <Form.Control
                                type="number"
                                placeholder="ტელ. ნომერი"
                                name="phoneNumber"
                            />
                        </FloatingLabel>

                        <div key="radio">
                            <Form.Label className="fw-bold">
                                მიწოდება
                            </Form.Label>
                            <Form.Check
                                type="radio"
                                name="shipping"
                                label="5₾ - თბილისში"
                                onClick={() => setShipping(1)}
                                // value={item.value}
                                // checked={
                                //   order.appointment.time ===
                                //   item.value
                                // }
                            />
                            <Form.Check
                                type="radio"
                                name="shipping"
                                label="10₾ - ყველა სხვა მისამართზე"
                                onClick={() => setShipping(2)}
                            />
                        </div>
                        <div className="text-center mt-3 h5">
                            სულ გადასახდელი: {total}₾
                        </div>
                        <div className="text-center text-muted">
                            ⚠️ გადახდის გვერდზე შეიყვანეთ გადასახდელი თანხა
                        </div>

                        <div className="text-center mt-3">
                            <Button
                                size="md"
                                variant="none"
                                className="brand-button"
                                type="submit"
                                // style={{
                                //     backgroundColor: COLORS.PRIMARY.TURQOISE,
                                //     borderColor: COLORS.PRIMARY.TURQOISE,
                                //     fontSize: "1.7rem",
                                // }}
                                // onClick={() => submit()}
                            >
                                ბარათით გადახდა
                            </Button>
                        </div>
                    </Form> */
}
