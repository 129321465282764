import Row from "react-bootstrap/Row";
import s from "./styles.module.css";

export default function Warranty() {
    return (
        <>
            <Row>
                <h2 className="text-3xl text-center mb-4 underline">
                    საგარანტიო პირობები
                </h2>
            </Row>
            <Row>
                <p>
                    გარანტიის ვადა შეძენიდან თითოეული პროდუქტისთვის
                    განისაზღვრება შემდეგი ვადით: 12 თვით.
                </p>
                <p className="mt-4">
                    გარანტიით სარგებლობისთვის მომხმარებელმა უნდა წარადგინოს
                    პროდუქტი, პროდუქტის ყუთი და ჩვენთან ან პარტნიორ იკომერს
                    პლატფორმიდან შეძენის დამადასტურებელი ქვითარი/ტრანზაქცია.
                    პროდუქტის დაბრუნების შემთხვევაში ტრანსპორტირების ხარჯებს
                    დაფარავს მომხმარებელი.
                </p>
                <p className="mt-4">
                    პროდუქტის გარანტიის პირობების მიხედვით შეკეთების ან შეცვლის
                    შემთხვევაში საწყისი გარანტიის პერიოდი იმოქმედებს და ხელახალი
                    ათვლა არ დაიწყება.
                </p>
                <p className="mt-4">
                    გარანტია არ ვრცელდება შემდეგ შემთხვევებში:
                </p>
                <ul className={s.unorderedList}>
                    <li className={s.listItem}>ვადაგასული გარანტია;</li>
                    <li className={s.listItem}>
                        პროდუქტის ჩვენთან ან ჩვენი პარტნიორი იკომერს
                        პლატფორმიდან შეძენის დამადასტურებელი გადახდის ქვითარის
                        გარეშე;
                    </li>
                    <li className={s.listItem}>პროდუქტის დაკარგვა/მოპარვა;</li>
                    <li className={s.listItem}>
                        გარეგნულად დაზიანებული, გატეხილი, გახსნილი, შენაკეთები
                        პროდუქტი;
                    </li>
                    <li className={s.listItem}>ზიანი გარე წყაროებიდან;</li>
                    <li className={s.listItem}>
                        პროდუქტის არასწორი გამოყენების შედეგად დაზიანებული (მათ
                        შორის, დაცემა/დავარდნა, ექსტრემალური ტემპერატურა,
                        წყლის/ორთქლის მოხვედრა მოწყობილობაში);
                    </li>
                </ul>
                <p className="mt-4">ნივთის შეკეთება/შეცვლის პირობები:</p>
                <p>
                    საგარანტიო მომსახურება გულისხმობს ნივთის შეკეთებას, ხოლო თუ
                    მისი შეკეთებით ვერ მოხერხდა მოწყობილობის ხარვეზის აღმოფხვრა,
                    ნივთი ექვემდებარება შეცვლას.
                </p>
            </Row>
        </>
    );
}
