import { Button, Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Item({ title, products, setShowModal }) {
    return (
        <Row className="mt-5">
            <h3 className={"mb-3 text-xl"}>{title}</h3>
            <hr />
            {products.map((product) => (
                <Col className="mt-4" key={product.id}>
                    <Card style={{ minWidth: "18rem" }}>
                        <Card.Img
                            variant="top"
                            loading="lazy"
                            src={product.imageUrl}
                        />
                        <Card.Body>
                            <Card.Title>
                                <Link to={product.id}>
                                    <p className="text-2xl font-medium">
                                        {product.title}
                                    </p>
                                </Link>
                            </Card.Title>
                            <Card.Text>
                                <p className="mb-2 text-xl text-green-700">
                                    {product.price}
                                </p>
                                {product.description.map(({ key, value }) => (
                                    <>
                                        <p>
                                            <strong>{key}</strong> {value}
                                        </p>
                                    </>
                                ))}
                            </Card.Text>
                            <Link to={product.id}>
                                <Button
                                    variant="outline-dark"
                                    size="sm"
                                    className="mt-3 mb-2"
                                >
                                    დეტალები
                                </Button>
                            </Link>
                            <Button
                                variant="outline-dark"
                                size="sm"
                                className="mt-3 mb-2 ml-5"
                                onClick={() => setShowModal(true)}
                            >
                                ყიდვა
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
            ))}
        </Row>
    );
}
