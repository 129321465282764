import Row from "react-bootstrap/Row";
import s from "./styles.module.css";

export default function TermsAndConditions() {
    return (
        <>
            <Row>
                <h2 className="text-3xl text-center mb-4 underline">
                    წესები და პირობები
                </h2>
            </Row>
        </>
    );
}
