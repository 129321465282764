import Item from "./Item";
import { OTHER, SECURITY_CAMERAS } from "../../products";
import CustomModal from "../../components/Modal";
import { useState } from "react";
import FeaturedIn from "./FeaturedIn";

export default function Home() {
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            <FeaturedIn />

            <Item
                title={"უსაფრთხოების კამერები"}
                products={SECURITY_CAMERAS}
                setShowModal={setShowModal}
            />

            <Item title={"სხვა პროდუქტები"} products={OTHER} />

            {/* <Row>
                    Some testimonials (რეკომენდატორები) https://testimonial.to/
                </Row> */}

            <CustomModal showModal={showModal} setShowModal={setShowModal} />
        </>
    );
}
