import { Navigate, useParams } from "react-router-dom";
import { Button, Col, Row, Table } from "react-bootstrap";
import { OTHER, SECURITY_CAMERAS } from "../../products";
import { useState } from "react";
import style from "./styles.module.css";
import CustomModal from "../../components/Modal";

const PRODUCTS = [...SECURITY_CAMERAS, ...OTHER];

export default function Product() {
    const { productId } = useParams();
    const product = PRODUCTS.find((product) => product.id === productId);
    if (!product) return <Navigate to="/" />;

    return (
        <div>
            <Details product={product} />
        </div>
    );
}

function Details({ product }) {
    const [showModal, setShowModal] = useState(false);

    return (
        <div>
            <Row>
                <Col md={6}>
                    <picture
                        className="mb-5 block overflow-hidden"
                        onClick={() => window.scrollTo(0, 500)}
                    >
                        <img
                            src={product.imageUrl}
                            loading="lazy"
                            alt={product.title}
                            className="w-full"
                        />
                    </picture>
                </Col>
                <Col>
                    <h2 className="text-2xl font-medium">{product.title}</h2>
                    <p className="mt-2 mb-2 text-2xl text-green-700">
                        {product.price}
                    </p>
                    <Button
                        variant="outline-dark"
                        size="md"
                        className="mb-4"
                        onClick={() => setShowModal(true)}
                    >
                        ყიდვა
                    </Button>

                    <ul className={`${style.unorderedList} ml-2`}>
                        {product.shortDescription?.map((item, index) => (
                            <li className={style.listItem} key={index}>
                                <strong className={style.strong}>
                                    {item.key}
                                </strong>{" "}
                                {item.value}
                            </li>
                        ))}
                    </ul>
                </Col>
            </Row>
            <Row>
                <Col></Col>
                <Col sm={12} md={10} lg={8}>
                    {product.video && (
                        <div className="flex flex-col items-center mt-3">
                            <iframe
                                className="w-full"
                                height="500"
                                src={product.video}
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerpolicy="strict-origin-when-cross-origin"
                                allowfullscreen
                            ></iframe>
                        </div>
                    )}
                    <Table striped="columns" className="mt-5">
                        <tbody>
                            {product.specs?.map((item, i) => (
                                <tr key={i.toString()}>
                                    <td>{item.key}</td>
                                    <td>{item.value}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
                <Col></Col>
            </Row>
            <CustomModal showModal={showModal} setShowModal={setShowModal} />
        </div>
    );
}
