import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";

export default function NotFound({ text }) {
    const { t } = useTranslation();

    return (
        <Container className="mt-5 text-center text-3xl underline">
            <h3>{text ? text : t("404")}</h3>
        </Container>
    );
}
